<template>
  <div ref="container" class="p-blockui-container">
    <slot></slot>
  </div>
</template>

<script>
import {DomHandler,ZIndexUtils} from 'primevue/utils';

export default {
  emits: ['block', 'unblock'],
  props: {
    strokeWidth: {
      type: Number,
      default: 3
    },
    blocked: {
      type: Boolean,
      default: false
    },
    fullScreen: {
      type: Boolean,
      default: false
    },
    baseZIndex: {
      type: Number,
      default: 0
    },
    autoZIndex: {
      type: Boolean,
      default: true
    }
  },
  mask: null,
  mounted() {
    if (this.blocked) {
      this.block();
    }
  },
  watch: {
    blocked(newValue) {
      if (newValue === true)
        this.block();
      else
        this.unblock();
    }
  },
  methods: {
    block() {
      if(this.mask){
        this.mask.remove();
      }

      this.mask = document.createElement('div');
      this.mask.innerHTML = '<div class="p-progress-spinner" role="alert" aria-busy="true">\n' +
          '        <svg class="p-progress-spinner-svg" viewBox="25 25 50 50" style="animation-duration: 2s">\n' +
          `            <circle class="p-progress-spinner-circle" cx="50" cy="50" r="20" fill="none" stroke-width="${this.strokeWidth}" stroke-miterlimit="10" />` +
          '        </svg>\n' +
          '    </div>';

      if (this.fullScreen) {
        this.mask.setAttribute('class', 'p-blockui p-blockui-document p-d-flex p-ai-center p-jc-center');
        document.body.appendChild(this.mask);
        DomHandler.addClass(document.body, 'p-overflow-hidden');
        document.activeElement.blur();
      }
      else {
        this.mask.setAttribute('class', 'p-blockui p-d-flex p-ai-center p-jc-center');
        this.$refs.container.appendChild(this.mask);
      }

      setTimeout(() => {
        DomHandler.addClass(this.mask, 'p-component-overlay');
      }, 1);

      if (this.autoZIndex) {
        ZIndexUtils.set('modal', this.mask, this.baseZIndex + this.$primevue.config.zIndex.modal);
      }
      this.$emit('block');
    },
    unblock() {
      DomHandler.addClass(this.mask, 'p-blockui-leave');
      this.mask.addEventListener('transitionend', () => {
        this.removeMask();
      });
    },
    removeMask() {
      ZIndexUtils.clear(this.mask);
      if (this.fullScreen) {
        document.body.removeChild(this.mask);
        DomHandler.removeClass(document.body, 'p-overflow-hidden');
      }
      else {
        this.$refs.container.removeChild(this.mask);
      }
      this.$emit('unblock');
    }
  }
}
</script>

<style>
.p-blockui-container {
  position: relative;
}
.p-blockui {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition-property: background-color;
}
.p-blockui.p-component-overlay {
  position: absolute;
}
.p-blockui-document.p-component-overlay {
  position: fixed;
}
.p-blockui.p-blockui-leave.p-component-overlay {
  background-color: transparent;
}
.p-progress-spinner {
  position: relative;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
}
.p-progress-spinner::before {
  content: '';
  display: block;
  padding-top: 100%;
}
.p-progress-spinner-svg {
  animation: p-progress-spinner-rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.p-progress-spinner-circle {
  stroke-dasharray: 89, 200;
  stroke-dashoffset: 0;
  stroke: #d62d20;
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite, p-progress-spinner-color 6s ease-in-out infinite;
  stroke-linecap: round;
}
@keyframes p-progress-spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes p-progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}
</style>
