import Repository from '@/services/Repository'
import { AxiosResponse } from 'axios'
import { StudentItem } from '@/models/training/student/student.item'
import { StudentRequest } from '@/models/training/student/student.request';
import { StudentResultRequest } from '@/models/training/student/student.result.request';

const resource = '/training/students';

class StudentRepository {
    get(): Promise<AxiosResponse<StudentItem[]>> {
        return Repository.get<StudentItem[]>(resource);
    }
    getByClass(classId: string): Promise<AxiosResponse<StudentItem[]>> {
        return Repository.get<StudentItem[]>(`${resource}/by-class/${classId}`);
    }
    createMulti(requests: StudentRequest[]): Promise<any> {
        return Repository.post(`${resource}/create-multi`, requests);
    }
    updateResult(requests: StudentResultRequest[]): Promise<any> {
        return Repository.put(`${resource}/update-result`, requests);
    }
    delete(id: string): Promise<AxiosResponse> {
        return Repository.delete(`${resource}/${id}`);
    }
}

export default new StudentRepository()
