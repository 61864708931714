import Repository from './Repository';
import { AxiosResponse } from 'axios'
import {WifiItem} from "@/models/wifi/wifi.item";
import {WifiRequest} from "@/models/wifi/wifi.request";

const resource = '/wifi';

class WifiRepository {

    delete(id: string): Promise<AxiosResponse> {
        return Repository.delete(`${resource}/${id}`);
    }

    update(id: string, wifiRequest: WifiRequest): Promise<any> {
        return Repository.put(`${resource}/${id}`, wifiRequest);
    }

    create(wifiRequest: WifiRequest): Promise<AxiosResponse<WifiItem>> {
        return Repository.post(resource, wifiRequest);
    }
}

export default new WifiRepository()