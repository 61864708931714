import Repository from '@/services/Repository';
import { AxiosResponse } from 'axios'
import { TrainingProgramItem } from '@/models/training/program/training.program.item';
import { TrainingProgramRequest } from '@/models/training/program/training.program.request';

const resource = '/training/training-programs';

class TrainingProgramRepository {
    get(): Promise<AxiosResponse<TrainingProgramItem[]>> {
        return Repository.get<TrainingProgramItem[]>(resource);
    }
    create(request: TrainingProgramRequest): Promise<AxiosResponse<TrainingProgramItem>> {
        return Repository.post(resource, request);
    }
    update(id: string, request: TrainingProgramRequest): Promise<any> {
        return Repository.put(`${resource}/${id}`, request);
    }
    delete(id: string): Promise<AxiosResponse> {
        return Repository.delete(`${resource}/${id}`);
    }
}

export default new TrainingProgramRepository()
