import {defineStore} from "pinia";

export const useUiState = defineStore({
    id: 'Ui State',
    state: () => ({
        appTitle: 'Y học cổ truyền'
    }),
    actions: {
        setAppTitle: function (appTitle: string) {
            this.appTitle = appTitle
        }
    }
})