import {defineStore} from "pinia";
import ShiftItem from "@/models/shift/shift.item";
import ShiftRepository from "@/services/ShiftRepository";
import { ShiftRequest } from "@/models/shift/shift.request";

const repository = ShiftRepository

export const useShiftStore = defineStore({
    id: 'Shifts',
    state: () => ({
        shifts: [] as ShiftItem[]
    }),
    actions: {
        getAll: async function() {
            const response = await repository.get()
            this.shifts = response.data
        },
        update: async function(id: string, shiftRequest: ShiftRequest) {
            await repository.update(id, shiftRequest)
            this.shifts = this.shifts.map(item => item.id === id ? {
                id: id,
                ... shiftRequest
            }: item )
        }
    }
})