import { pad } from '@/helper/formatting'

export function createDate (dt: string | Date | undefined): Date {
  if (!dt) {
    return new Date() // Today as default
  }

  if (typeof dt === 'string') {
    let exec = /(\d{1,2})[-/:](\d{1,2})[-/:](\d{4})/.exec(dt)

    if (exec) {
      return new Date(+exec[3], +exec[2] - 1, +exec[1])
    }

    exec = /(\d{4})[-/:](\d{1,2})[-/:](\d{1,2})/.exec(dt)

    if (exec) {
      return new Date(+exec[1], +exec[2] - 1, +exec[3])
    }

    return new Date(dt)
  }

  return new Date(dt)
}

export function toPickerString (dt: Date) : string {
  return `${pad(dt.getDate())}-${pad(dt.getMonth() + 1)}-${dt.getFullYear()}`
}

export function toDateDisplay (dt: string | Date | undefined): string {
  if (!dt) {
    return ''
  }

  const date = createDate(dt)
  return `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`
}

export function toDateValue (dt: string | Date | undefined, seperator: string = '/'): string {
  const date = createDate(dt)
  return `${date.getFullYear()}${seperator}${pad(date.getMonth() + 1)}${seperator}${pad(date.getDate())}`
}

export function todayValue () : string {
  const date = new Date()
  return `${date.getFullYear()}/${pad(date.getMonth() + 1)}/${pad(date.getDate())}`
}

export function firstDateOfMonthValue () : Date {
  const date = new Date()
  return new Date(`${date.getFullYear()}/${pad(date.getMonth() + 1)}/1`)
}

export function nowTimeString (): string {
  const date = new Date()
  return `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`
}

export function toFriendlyDate (dt: string | Date | undefined, isHomNay: Boolean): string {
  if (!dt) {
    return ''
  }

  if (isHomNay && isToday(dt)) {
    return 'Hôm nay'
  }

  const date = new Date(dt)

  if (date.getDay() === 0) {
    return `Chủ nhật, ${toDateDisplay(dt)}`
  }

  return `Thứ ${date.getDay() + 1}, ${toDateDisplay(dt)}`
}

export function isSameDate (date: Date, another: Date): boolean {
  return date.getFullYear() === another.getFullYear() &&
    date.getMonth() === another.getMonth() &&
    date.getDate() === another.getDate()
}

export function isToday (dt: string | Date | undefined): boolean {
  if (!dt) {
    return false
  }

  const date = new Date(dt)
  const today = new Date()

  return isSameDate(date, today)
}
