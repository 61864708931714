import { UserRequest } from '@/models/user/user.request';
import {UserDetail} from "@/models/user/user.detail";

export function getUserDisplay (user: UserDetail): string {
    return user ? `${user.firstName} ${user.lastName}` : ''
}

export function validUser (userRequest: UserRequest): boolean {
    return !!userRequest.email &&
        !!userRequest.firstName &&
        !!userRequest.lastName &&
        !!userRequest.username;
}

