import { defineStore } from "pinia"
import TrainingProgramRepository from "@/services/training/TrainingProgramRepository"
import { TrainingProgramRequest } from "@/models/training/program/training.program.request"
import { TrainingProgramItem } from "@/models/training/program/training.program.item"

export const useTrainingProgramStore = defineStore({
    id: 'TrainingProgram state',
    state: () => ({
        trainingPrograms: [] as TrainingProgramItem[]
    }),
    getters: {
        findTrainingProgram: (state) => {
            return (id: String) => state.trainingPrograms.find(x => x.id === id)
        }
    },
    actions: {
        getAll: async function () {
            const response = await TrainingProgramRepository.get()
            this.trainingPrograms = response.data
        },
        create: async function (request: TrainingProgramRequest) {
            const response = await TrainingProgramRepository.create(request)
            this.trainingPrograms = [...this.trainingPrograms, response.data]
        },
        update: async function (id: string, request: TrainingProgramRequest) {
            const response = await TrainingProgramRepository.update(id, request)
            this.trainingPrograms = this.trainingPrograms.map(x => x.id === id ? response.data : x)
        },
        delete: async function (id: string) {
            await TrainingProgramRepository.delete(id)
            this.trainingPrograms = this.trainingPrograms.filter(x => x.id !== id)
        }
    }
})
