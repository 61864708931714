import Repository from '@/services/Repository'
import { AxiosResponse } from 'axios'
import { NumberLessonResponse } from '@/models/training/report/number.lesson.response'
import { ByStudentResponse } from '@/models/training/report/by.student.response';
import { LessonOfTeacherByClassResponse } from '@/models/training/report/lesson.of.teacher.by.class.response';
import { LessonOfClassByTeacherResponse } from '@/models/training/report/lesson.of.class.by.teacher.response';

const resource = '/training/reports'

class ReportRepository {
    reportNumberLesson(fromDate: string, toDate: string): Promise<AxiosResponse<NumberLessonResponse[]>> {
        return Repository.get<NumberLessonResponse[]>(`${resource}/report-number-lesson/${fromDate}/${toDate}`);
    }
    reportByStudent(userId: string): Promise<AxiosResponse<ByStudentResponse[]>> {
        return Repository.get<ByStudentResponse[]>(`${resource}/report-by-student/${userId}`);
    }
    reportLessonOfTeacherByClass(): Promise<AxiosResponse<LessonOfTeacherByClassResponse[]>> {
        return Repository.get<LessonOfTeacherByClassResponse[]>(`${resource}/report-lesson-of-teacher-by-class`);
    }
    reportLessonOfClassByTeacher(): Promise<AxiosResponse<LessonOfClassByTeacherResponse[]>> {
        return Repository.get<LessonOfClassByTeacherResponse[]>(`${resource}/report-lesson-of-class-by-teacher`);
    }
}

export default new ReportRepository()
