import {defineStore} from "pinia";
import LeaveReasonRepository from "@/services/LeaveReasonRepository";
import {LeaveReasonRequest} from "@/models/leave-reason/leave.reason.request";
import {LeaveReasonItem} from "@/models/leave-reason/leave.reason.item";

export const useLeaveReasonStore = defineStore({
    id: 'Leave Reason state',
    state: () => ({
        leaveReasons: [] as LeaveReasonItem[]
    }),
    actions: {
        getAll: async function () {
            const response = await LeaveReasonRepository.get()
            this.leaveReasons = response.data
        },
        delete: async function(id: string) {
            await LeaveReasonRepository.delete(id)
            this.leaveReasons = this.leaveReasons.filter(x => x.id !== id);
        },
        create: async function(leaveReasonRequest: LeaveReasonRequest) {
            const response = await LeaveReasonRepository.create(leaveReasonRequest)
            this.leaveReasons = [ ...this.leaveReasons, response.data ];
        },
        update: async function(id: string, leaveReasonRequest: LeaveReasonRequest) {
            const response = await LeaveReasonRepository.update(id, leaveReasonRequest);
            this.leaveReasons = this.leaveReasons.map(x => x.id === id ? response.data : x);
        }
    }
})
