import { defineStore } from "pinia";
import TrainingUnitRepository from "@/services/training/TrainingUnitRepository";
import { TrainingUnitRequest } from "@/models/training/unit/training.unit.request";
import { TrainingUnitItem } from "@/models/training/unit/training.unit.item";

export const useTrainingUnitStore = defineStore({
    id: 'TrainingUnit state',
    state: () => ({
        trainingUnits: [] as TrainingUnitItem[]
    }),
    getters: {
        findTrainingUnit: (state) => {
            return (id: String) => state.trainingUnits.find(x => x.id === id)
        }
    },
    actions: {
        getAll: async function () {
            const response = await TrainingUnitRepository.get()
            this.trainingUnits = response.data
        },
        create: async function (request: TrainingUnitRequest) {
            const response = await TrainingUnitRepository.create(request)
            this.trainingUnits = [...this.trainingUnits, response.data];
        },
        update: async function (id: string, request: TrainingUnitRequest) {
            const response = await TrainingUnitRepository.update(id, request);
            this.trainingUnits = this.trainingUnits.map(x => x.id === id ? response.data : x);
        },
        delete: async function (id: string) {
            await TrainingUnitRepository.delete(id)
            this.trainingUnits = this.trainingUnits.filter(x => x.id !== id);
        }
    }
})
