import Repository from './Repository';
import { AxiosResponse } from 'axios'
import PasswordForm from '@/models/profile/password';

const resource = '/profile';

class ProfileRepository {
    changePassword(passwordForm: PasswordForm): Promise<AxiosResponse>{
        return Repository.post(`${resource}/password`, passwordForm);
    }
}

export default new ProfileRepository()