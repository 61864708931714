import { defineStore } from "pinia";
import TeacherRepository from "@/services/training/TeacherRepository"
import { TeacherRequest } from "@/models/training/teacher/teacher.request"
import { TeacherItem } from "@/models/training/teacher/teacher.item"

export const useTeacherStore = defineStore({
    id: 'Teacher state',
    state: () => ({
        teachers: [] as TeacherItem[],
        loading: false
    }),
    getters: {
        findTeacher: (state) => {
            return (id: String) => state.teachers.find(x => x.id === id)
        }
    },
    actions: {
        getAll: async function () {
            this.loading = true
            const response = await TeacherRepository.get()
            this.teachers = response.data
            this.loading = false
        },
        create: async function (request: TeacherRequest) {
            const response = await TeacherRepository.create(request)
            this.teachers = [...this.teachers, response.data]
        },
        update: async function (id: string, request: TeacherRequest) {
            const response = await TeacherRepository.update(id, request);
            this.teachers = this.teachers.map(x => x.id === id ? response.data : x);
        },
        delete: async function (id: string) {
            await TeacherRepository.delete(id)
            this.teachers = this.teachers.filter(x => x.id !== id);
        }
    }
})
