import Repository from '@/services/Repository';
import { AxiosResponse } from 'axios'
import { TrainingTypeItem } from '@/models/training/type/training.type.item';
import { TrainingTypeRequest } from '@/models/training/type/training.type.request';

const resource = '/training/training-types';

class TrainingTypeRepository {
    get(): Promise<AxiosResponse<TrainingTypeItem[]>> {
        return Repository.get<TrainingTypeItem[]>(resource);
    }
    create(request: TrainingTypeRequest): Promise<AxiosResponse<TrainingTypeItem>> {
        return Repository.post(resource, request);
    }
    update(id: string, request: TrainingTypeRequest): Promise<any> {
        return Repository.put(`${resource}/${id}`, request);
    }
    delete(id: string): Promise<AxiosResponse> {
        return Repository.delete(`${resource}/${id}`);
    }
}

export default new TrainingTypeRepository()
