import useNotification from "@/logics/useNotification"
import { emptyLogin } from "@/models/auth/login"
import router from "@/router"
import { computed, ref } from "vue"
import { useAuthStore } from "@/store/auth"
import { useData } from "@/logics/useData"
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth"

export function useAuth() {
    const loginForm = ref({ ...emptyLogin });
    const notification = useNotification();

    const authStore = useAuthStore()
    const data = useData()

    const login = async () => {
        try {
            await authStore.login(loginForm.value);
            await data.loadAll()

            notification.showMessage('Đăng nhập thành công vào hệ thống');
            await router.push({ name: 'app' });
        } catch (e) {
            console.error(e)
        }
    }

    const loginWithGoogle = async () => {
        try {
            const auth = getAuth()
            const provider = new GoogleAuthProvider()
            const token = await signInWithPopup(auth, provider)
                .then(x => GoogleAuthProvider.credentialFromResult(x)?.idToken ?? '')
            await authStore.loginGoogle({token})
            await data.loadAll()

            notification.showMessage('Đăng nhập thành công vào hệ thống')
            await router.push({name: 'app'})
        } catch (e) {
            console.error(e)
        }
    }

    const logout = async () => {
        await authStore.logout()
    }

    const currentUser = computed(() => authStore.currentUser);
    const isLoggedIn = computed(() => !!authStore.token);
    const valid = computed(() => loginForm.value.email && loginForm.value.password);

    return {
        valid,
        loginForm,
        isLoggedIn,
        currentUser,
        login,
        loginWithGoogle,
        logout,
    }
}
