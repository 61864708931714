import Repository from './Repository';
import { AxiosResponse } from 'axios'
import { LeaveReasonItem } from '@/models/leave-reason/leave.reason.item';
import { LeaveReasonRequest } from '@/models/leave-reason/leave.reason.request';

const resource = '/leave-reasons';

class LeaveReasonRepository {
    get(): Promise<AxiosResponse<LeaveReasonItem[]>> {
        return Repository.get<LeaveReasonItem[]>(resource);
    }

    delete(id: string): Promise<AxiosResponse> {
        return Repository.delete(`${resource}/${id}`);
    }

    update(id: string, department: LeaveReasonRequest): Promise<any> {
        return Repository.put(`${resource}/${id}`, department);
    }

    create(department: LeaveReasonRequest): Promise<AxiosResponse<LeaveReasonItem>> {
        return Repository.post(resource, department);
    }
}

export default new LeaveReasonRepository()
