import { Ref } from "vue";


export async function callAPI (action: Promise<any> | Promise<void>, 
    apiWorkingRef: Ref<boolean>, 
    taskWorkingRef: Ref<boolean>) {
    apiWorkingRef.value = true;
    try {
        await action;
        taskWorkingRef.value = false;
    } catch(err){
        console.log('Called api failed');
    } finally {
        apiWorkingRef.value = false;
    }
}