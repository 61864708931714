import {Gender} from '../enums/gender';
import {Role} from '../enums/role';

export interface UserRequest {
    id: string;
    email: string;
    username: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    role: Role;
    phoneNumber: string;
    departmentId?: string;
    address: string;
    identityCard: string;
    bankAccount: string;
    admin: boolean;
    password: string;
}

export const emptyUserRequest: UserRequest = {
    id: '',
    email: '',
    username: '',
    firstName: '',
    lastName: '',
    gender: Gender.Male,
    departmentId: '',
    role: Role.Employee,
    address: '',
    identityCard: '',
    bankAccount: '',
    admin: false,
    password: '',
    phoneNumber: ''
}