import {SalaryStatus} from "@/models/enums/salary.status";

export interface SalaryResponseToAction {
    id: string
    name: string
    lastSent?: Date
    status: SalaryStatus
}

export const emptySalaryResponseToAction: SalaryResponseToAction = {
    id: '',
    name: '',
    status: SalaryStatus.Pending
}
