import { defineStore } from "pinia";
import ClassRepository from "@/services/training/ClassRepository"
import { ClassRequest } from "@/models/training/class/class.request"
import { ClassItem, emptyClassItem } from "@/models/training/class/class.item"

export const useClassStore = defineStore({
    id: 'Class state',
    state: () => ({
        classes: [] as ClassItem[],
        loading: false,
        classItem: emptyClassItem
    }),
    getters: {
        findClass: (state) => {
            return (id: String) => state.classes.find(x => x.id === id)
        }
    },
    actions: {
        getAll: async function () {
            this.loading = true
            const response = await ClassRepository.getAll()
            this.classes = response.data
            this.loading = false
        },
        getById: async function (id: string) {
            const response = await ClassRepository.getById(id)
            this.classItem = response.data
        },
        create: async function (request: ClassRequest) {
            const response = await ClassRepository.create(request)
            this.classes = [...this.classes, response.data]
        },
        update: async function (id: string, request: ClassRequest) {
            const response = await ClassRepository.update(id, request);
            this.classes = this.classes.map(x => x.id === id ? response.data : x);
        },
        delete: async function (id: string) {
            await ClassRepository.delete(id)
            this.classes = this.classes.filter(x => x.id !== id);
        }
    }
})
