import { defineStore } from "pinia";
import TrainingTypeRepository from "@/services/training/TrainingTypeRepository";
import { TrainingTypeRequest } from "@/models/training/type/training.type.request";
import { TrainingTypeItem } from "@/models/training/type/training.type.item";

export const useTrainingTypeStore = defineStore({
    id: 'TrainingType state',
    state: () => ({
        trainingTypes: [] as TrainingTypeItem[]
    }),
    getters: {
        findTrainingType: (state) => {
            return (id: String) => state.trainingTypes.find(x => x.id === id)
        }
    },
    actions: {
        getAll: async function () {
            const response = await TrainingTypeRepository.get()
            this.trainingTypes = response.data
        },
        create: async function (request: TrainingTypeRequest) {
            const response = await TrainingTypeRepository.create(request)
            this.trainingTypes = [...this.trainingTypes, response.data];
        },
        update: async function (id: string, request: TrainingTypeRequest) {
            const response = await TrainingTypeRepository.update(id, request);
            this.trainingTypes = this.trainingTypes.map(x => x.id === id ? response.data : x);
        },
        delete: async function (id: string) {
            await TrainingTypeRepository.delete(id)
            this.trainingTypes = this.trainingTypes.filter(x => x.id !== id);
        }
    }
})
