export interface SalaryResponseErrorInfo {
    id: string
    name: string
    jsonErrorFileContent: string
    jsonErrorSheetName: string
    salaryFile: SalaryFile
}

export interface SalaryFile {
    id: string
    filename: string
}

export const emptySalaryResponseErrorInfo: SalaryResponseErrorInfo = {
    id: '',
    name: '',
    jsonErrorFileContent: '[]',
    jsonErrorSheetName: '[]',
    salaryFile: {
        id: '',
        filename: ''
    }
}
