import App from "@/App.vue";
import LoginPage from "@/pages/LoginPage.vue";
import LogoutPage from "@/pages/LogoutPage.vue";
import DepartmentPage from '@/pages/DepartmentPage.vue';
import LeaveReasonPage from '@/pages/LeaveReasonPage.vue';
import UserPage from '@/pages/UserPage.vue';
import BuildingPage from '@/pages/BuildingPage.vue';
import TitlePage from '@/pages/TitlePage.vue';
import ShiftPage from '@/pages/ShiftPage.vue';
import SalaryPage from '@/pages/SalaryPage.vue';
import SalaryDetail from '@/components/salary/SalaryDetail.vue';

import TrainingTypePage from '@/pages/training/TrainingTypePage.vue'
import TrainingProgramPage from '@/pages/training/TrainingProgramPage.vue'
import TrainingUnitPage from '@/pages/training/TrainingUnitPage.vue'
import TeacherPage from '@/pages/training/TeacherPage.vue'
import ClassPage from '@/pages/training/ClassPage.vue'
import ClassDetail from '@/components/training/class/ClassDetail.vue'

import ReportNumberLessonPage from '@/pages/training/ReportNumberLessonPage.vue'
import ReportByStudentPage from '@/pages/training/ReportByStudentPage.vue'
import ReportLessonOfTeacherByClassPage from '@/pages/training/ReportLessonOfTeacherByClassPage.vue'
import ReportLessonOfClassByTeacherPage from '@/pages/training/ReportLessonOfClassByTeacherPage.vue'

import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '',
        name: 'app',
        component: App,
        children: [
            {
                path: '',
                alias: 'index.html',
                name: 'parent',
                redirect: { name: 'users' }
            },
            {
                path: 'users',
                name: 'users',
                component: UserPage
            },
            {
                path: 'salaries',
                name: 'salaries',
                component: SalaryPage
            },
            {
                path: 'salaries/:id',
                name: 'salary-detail',
                component: SalaryDetail,
                props: true
            },
            {
                path: 'departments',
                name: 'departments',
                component: DepartmentPage
            },
            {
                path: 'leave-reasons',
                name: 'leave-reasons',
                component: LeaveReasonPage
            },
            {
                path: 'buildings',
                name: 'buildings',
                component: BuildingPage
            },
            {
                path: 'titles',
                name: 'titles',
                component: TitlePage
            },
            {
                path: 'shifts',
                name: 'shifts',
                component: ShiftPage
            },



            {
                path: 'training/training-types',
                name: 'training-types',
                component: TrainingTypePage
            },
            {
                path: 'training/training-programs',
                name: 'training-programs',
                component: TrainingProgramPage
            },
            {
                path: 'training/training-units',
                name: 'training-units',
                component: TrainingUnitPage
            },
            {
                path: 'training/teachers',
                name: 'teachers',
                component: TeacherPage
            },
            {
                path: 'training/classes',
                name: 'classes',
                component: ClassPage
            },
            {
                path: 'training/classes/:id',
                name: 'class-detail',
                component: ClassDetail,
                props: true
            },
            {
                path: 'training/report-number-lessons',
                name: 'report-number-lessons',
                component: ReportNumberLessonPage
            },
            {
                path: 'training/report-by-student',
                name: 'report-by-student',
                component: ReportByStudentPage
            },
            {
                path: 'training/report-lesson-of-teacher-by-class',
                name: 'report-lesson-of-teacher-by-class',
                component: ReportLessonOfTeacherByClassPage
            },
            {
                path: 'training/report-lesson-of-class-by-teacher',
                name: 'report-lesson-of-class-by-teacher',
                component: ReportLessonOfClassByTeacherPage
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage
    },
    {
        path: '/logout',
        name: 'logout',
        component: LogoutPage
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

import {useAuthStore} from "@/store/auth";

router.beforeEach((to, from, next) => {
    const authStore = useAuthStore()

    if (to.name !== 'login' && !authStore.token) {
        next({ name: 'login' })
    }

    next();
})

export default router;
