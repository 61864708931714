import { defineStore } from "pinia";
import ScheduleRepository from "@/services/training/ScheduleRepository"
import { ScheduleItem } from "@/models/training/schedule/schedule.item"
import { ScheduleRequest } from "@/models/training/schedule/schedule.request";

export const useScheduleStore = defineStore({
    id: 'Schedule state',
    state: () => ({
        schedules: [] as ScheduleItem[]
    }),
    getters: {
        findSchedule: (state) => {
            return (id: String) => state.schedules.find(x => x.id === id)
        }
    },
    actions: {
        getAll: async function () {
            const response = await ScheduleRepository.get()
            this.schedules = response.data
        },
        getByClass: async function (classId: string) {
            const response = await ScheduleRepository.getByClass(classId)
            this.schedules = response.data
        },
        create: async function (request: ScheduleRequest) {
            const response = await ScheduleRepository.create(request)
            this.schedules = [...this.schedules, response.data]
        },
        update: async function (id: string, request: ScheduleRequest) {
            const response = await ScheduleRepository.update(id, request);
            this.schedules = this.schedules.map(x => x.id === id ? response.data : x);
        },
        delete: async function (id: string) {
            await ScheduleRepository.delete(id)
            this.schedules = this.schedules.filter(x => x.id !== id);
        }
    }
})
