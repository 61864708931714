import Repository from '@/services/Repository'
import { AxiosResponse } from 'axios'
import { TrainingProgramFileResponse } from '@/models/training/program/training.program.file.response'

const resource = '/training/files'

class TrainingProgramFileRepository {
    getAll(): Promise<AxiosResponse<TrainingProgramFileResponse[]>> {
        return Repository.get<TrainingProgramFileResponse[]>(resource)
    }

    uploadFile(formData: FormData): Promise<AxiosResponse<TrainingProgramFileResponse[]>> {
        return Repository.post(resource, formData)
    }

    delete(id: string): Promise<AxiosResponse> {
        return Repository.delete(`${resource}/${id}`)
    }

    getFileUrl(id: string): string {
        return `${Repository.defaults.baseURL}${resource}/${id}`
    }
}

export default new TrainingProgramFileRepository()
