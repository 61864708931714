import { Token } from "@/models/auth/token"
import Repository from './Repository'
import { AxiosResponse } from 'axios'
import { Login } from "@/models/auth/login"
import { UserDetail } from '@/models/user/user.detail'

const resource = '/auth'

class AuthRepository {
    auth(login: Login): Promise<AxiosResponse<Token>> {
        return Repository.post<Token>(`${resource}/admin`, login)
    }

    authGoogle(googleLogin: Token): Promise<AxiosResponse<Token>> {
        return Repository.post<Token>(`${resource}/admin/google`, googleLogin)
    }

    getUser(): Promise<AxiosResponse<UserDetail>> {
        return Repository.get<UserDetail>(resource)
    }
}

export default new AuthRepository()