export interface BuildingRequest {
    name: string;
    description: string;
    lat?: number;
    lng?: number;
    radius: number;
}

export const emptyBuilding: BuildingRequest = {
    name: '',
    description: '',
    lat: 16.0653942,
    lng: 108.2144241,
    radius: 100
}