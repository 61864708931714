import {defineStore} from "pinia";
import BuildingRepository from "@/services/BuildingRepository";
import WifiRepository from "@/services/WifiRepository";
import {WifiItem} from "@/models/wifi/wifi.item";
import {WifiRequest} from "@/models/wifi/wifi.request";


export const useWifiStore = defineStore({
    id: 'Wifi Store',
    state: () => ({
        wifi: [] as WifiItem[]
    }),
    actions: {
        getAll: async function(id: string) {
            this.wifi = []
            const response = await BuildingRepository.getWifi(id)
            this.wifi = response.data
        },
        create: async function(wifiRequest: WifiRequest) {
            const response = await WifiRepository.create(wifiRequest)
            this.wifi = [...this.wifi, response.data]
        },
        update: async function(id: string, wifiRequest: WifiRequest) {
            await WifiRepository.update(id, wifiRequest)
            this.wifi = this.wifi.map(item => item.id === id ? {
                id: id,
                ... wifiRequest
            }: item )
        },
        delete: async function(id: string) {
            await WifiRepository.delete(id)
            this.wifi = this.wifi.filter(x => x.id !== id)
        }
    }
})