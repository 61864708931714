import {defineStore} from "pinia";
import SalaryRepository from "@/services/SalaryRepository";
import {SalaryRequest} from "@/models/salary/salary.request";
import {SalaryItem} from "@/models/salary/salary.item";
import {emptySalaryResponseToAction} from "@/models/salary/salary.response.to.action";

const repository = SalaryRepository

export const useSalaryStore = defineStore({
    id: 'Salary store',
    state: () => ({
        salaryCommonInfo: [] as SalaryItem[],
        salaryToAction: emptySalaryResponseToAction
    }),
    getters: {
        findSalary: (state) => {
            return (id: String) => state.salaryCommonInfo.find(x => x.id === id)
        }
    },
    actions: {
        getAll: async function () {
            const response = await repository.getAll()
            this.salaryCommonInfo = response.data
        },
        getById: async function (id: string) {
            const response = await repository.getById(id)
            this.salaryToAction = response.data
        },
        create: async function (request: SalaryRequest) {
            const response = await repository.create(request)
            this.salaryCommonInfo = [...this.salaryCommonInfo, response.data]
        },
        changeName: async function (id: string, request: SalaryRequest) {
            await repository.changeName(id, request)
            this.salaryCommonInfo = this.salaryCommonInfo.map(item => item.id === id ? {
                ...item,
                ...request
            }: item )
        },
        delete: async function (id: string) {
            await repository.delete(id)
            this.salaryCommonInfo = this.salaryCommonInfo.filter(x => x.id !== id)
        },
        sendNotification: async function (id: string) {
            const response = await repository.sendNotification(id);
            this.salaryCommonInfo = this.salaryCommonInfo.map(item => item.id === id ? {
                ...item,
                ...response.data
            }: item );
            this.salaryToAction = {
                ...response.data
            }
        }
    }
})
