import Repository from './Repository';
import {AxiosResponse} from 'axios'
import {SalaryRequest} from '@/models/salary/salary.request';
import {SalaryItem} from "@/models/salary/salary.item";
import {SalaryResponseErrorInfo} from "@/models/salary/salary.response.error.info";
import {SalaryResponseOkInfo} from "@/models/salary/salary.response.ok.info";
import {SalaryResponseToAction} from "@/models/salary/salary.response.to.action";

const resource = '/salaries';

class SalaryRepository {
    getAll(): Promise<AxiosResponse<SalaryItem[]>> {
        return Repository.get<SalaryItem[]>(`${resource}`);
    }

    getById(id: string): Promise<AxiosResponse<SalaryResponseToAction>> {
        return Repository.get<SalaryResponseToAction>(`${resource}/${id}`);
    }

    getErrorInfoById(id: string): Promise<AxiosResponse<SalaryResponseErrorInfo>> {
        return Repository.get<SalaryResponseErrorInfo>(`${resource}/${id}/error-info`);
    }

    getOkInfoById(id: string): Promise<AxiosResponse<SalaryResponseOkInfo[]>> {
        return Repository.get<SalaryResponseOkInfo[]>(`${resource}/${id}/ok-info`);
    }

    create(request: SalaryRequest): Promise<AxiosResponse<SalaryItem>> {
        const formData = new FormData();
        formData.append('Name', request.name)
        formData.append('FormFile', request.formFile)
        return Repository.post(resource, formData);
    }

    changeName(id: string, request: SalaryRequest): Promise<any> {
        return Repository.put(`${resource}/${id}`, request);
    }

    delete(id: string): Promise<AxiosResponse> {
        return Repository.delete(`${resource}/${id}`);
    }

    sendNotification(id: string): Promise<AxiosResponse<SalaryResponseToAction>> {
        return Repository.put(`${resource}/${id}/send-notification`);
    }
}

export default new SalaryRepository()
