import { defineStore } from "pinia"
import { TrainingProgramFileResponse } from '@/models/training/program/training.program.file.response'
import TrainingProgramFileRepository from '@/services/training/TrainingProgramFileRepository'

export const useTrainingProgramFileStore = defineStore({
    id: 'TrainingProgramFile state',
    state: () => ({
        trainingProgramFiles: [] as TrainingProgramFileResponse[]
    }),
    getters: {
        findTrainingProgramFile: (state) => {
            return (id: String) => state.trainingProgramFiles.find(x => x.id === id)
        }
    },
    actions: {
        getAll: async function () {
            const response = await TrainingProgramFileRepository.getAll()
            this.trainingProgramFiles = response.data
        },
        uploadFile: async function (formData: FormData) {
            const response = await TrainingProgramFileRepository.uploadFile(formData)
            this.trainingProgramFiles = [...this.trainingProgramFiles, ...response.data]
        },
        delete: async function (id: string) {
            await TrainingProgramFileRepository.delete(id)
            this.trainingProgramFiles = this.trainingProgramFiles.filter(x => x.id !== id)
        }
    }
})
