import { ClassStatus } from "./class.status"
import { toDateValue } from '@/helper/date'
import { ClassRequest } from "./class.request"

export interface ClassItem extends ClassRequest {
    id: string
    dateText?: string
    trainingUnitNames?: string
}

export const emptyClassItem: ClassItem = {
    id: '',
    name: '',
    trainingProgramId: '',
    trainingTypeId: '',
    trainingUnitIds: '',
    fromDate: toDateValue(new Date()),
    toDate: toDateValue(new Date()),
    status: ClassStatus.Openning
}
