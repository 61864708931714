import { AxiosResponse } from "axios";
import Repository from "@/services/Repository";
import ShiftItem from "@/models/shift/shift.item";
import { ShiftRequest } from "@/models/shift/shift.request";

const resource = '/shifts';

class ShiftRepository {
    get(): Promise<AxiosResponse<ShiftItem[]>> {
        return Repository.get<ShiftItem[]>(resource);
    }

    update(id: string, shiftRequest: ShiftRequest): Promise<AxiosResponse> {
        return Repository.put(`${resource}/${id}`, shiftRequest)
    }
}

export default new ShiftRepository()