import { CURRENT_USER, TOKEN } from './const'
import {emptyUser, UserDetail} from "@/models/user/user.detail";

function getStringFromLocal (key: string) : string {
  return localStorage.getItem(key) || ''
}

function getUserFromLocal (): UserDetail {
  const user = getStringFromLocal(CURRENT_USER)
  if (user) {
    return JSON.parse(user) as UserDetail
  }
  return emptyUser
}

export interface AuthsState {
  token: string
  currentUser: UserDetail
}

export const initState: AuthsState = {
  token: getStringFromLocal(TOKEN),
  currentUser: getUserFromLocal()
}
