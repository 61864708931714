export interface WifiRequest {
    buildingId: string;
    name: string;
    description: string;
    mac: string;
}

export const emptyWifiRequest: WifiRequest = {
    buildingId: '',
    name: '',
    description: '',
    mac: ''
}