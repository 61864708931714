import {defineStore} from "pinia";
import UserRepository from "@/services/UserRepository";
import {UserRequest} from "@/models/user/user.request";
import {UserItem} from "@/models/user/user.item";

export const useUserStore = defineStore({
    id: 'Uses state',
    state: () => ({
        users: [] as UserItem[],
        loading: false
    }),
    actions: {
        getAll: async function () {
            this.loading = true
            const response = await UserRepository.get()
            this.users = response.data
            this.loading = false
        },
        delete: async function(id: string) {
            await UserRepository.delete(id)
            this.users = this.users.filter(x => x.id !== id);
        },
        create: async function(userRequest: UserRequest) {
            const response = await UserRepository.create(userRequest)
            this.users = [ ...this.users, response.data ];
        },
        update: async function(id: string, userRequest: UserRequest) {
            const response = await UserRepository.update(id, userRequest);
            this.users = this.users.map(x => x.id === id ? response.data : x);
        }
    }
})