import {DepartmentItem, emptyDepartmentItem} from '../department/department.item';
import { Gender } from '../enums/gender';
import { Role } from '../enums/role';

export interface UserDetail {
    id: string;
    email: string;
    username: string;
    firstName: string;
    lastName: string;
    birthday: Date;
    gender: Gender;
    role: Role;
    phoneNumber: string;
    departmentId: string;
    department: DepartmentItem;
    address: string;
    admin: boolean;
}

export const emptyUser : UserDetail = {
    id: '',
    email: '',
    username: '',
    firstName: '',
    lastName: '',
    departmentId: '',
    birthday: new Date(),
    role: Role.Employee,
    gender: Gender.Male,
    department: emptyDepartmentItem,
    address: '',
    admin: false,
    phoneNumber: ''
}