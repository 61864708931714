import {useAuthStore} from "@/store/auth";
import {useDepartmentStore} from "@/store/department";
import {useLeaveReasonStore} from "@/store/leave.reasons";
import {useUserStore} from "@/store/user";
import {useSalaryStore} from "@/store/salary";
import {useBuildingStore} from "@/store/building";
import {useTitleStore} from "@/store/title";
import {useShiftStore} from "@/store/shift";

import {useTrainingProgramStore} from "@/store/training/program"
import {useTrainingTypeStore} from "@/store/training/type"
import {useTrainingUnitStore} from "@/store/training/unit"
import {useTeacherStore} from "@/store/training/teacher"
import {useClassStore} from "@/store/training/class"

export function useData() {

    const authStore = useAuthStore()
    const departmentStore = useDepartmentStore()
    const leaveReasonStore = useLeaveReasonStore()
    const userStore = useUserStore()
    const salaryStore = useSalaryStore()
    const buildingStore = useBuildingStore()
    const titleStore = useTitleStore()
    const shiftStore = useShiftStore()

    const trainingProgramStore = useTrainingProgramStore()
    const trainingTypeStore = useTrainingTypeStore()
    const trainingUnitStore = useTrainingUnitStore()
    const teacherStore = useTeacherStore()
    const classStore = useClassStore()

    const loadAll = async () => {
        if (authStore.token) {
            await authStore.getCurrentUser()
            await departmentStore.getAll()
            await leaveReasonStore.getAll()
            await userStore.getAll()
            await salaryStore.getAll()
            await buildingStore.getAll()
            await titleStore.getAll()
            await shiftStore.getAll()

            await trainingProgramStore.getAll()
            await trainingTypeStore.getAll()
            await trainingUnitStore.getAll()
            await teacherStore.getAll()
            await classStore.getAll()
        }
    }

    return {
        loadAll
    }
}
