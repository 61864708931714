import {defineStore} from "pinia";
import BuildingRepository from "@/services/BuildingRepository";
import {BuildingItem} from "@/models/building/building.item";
import {BuildingRequest} from "@/models/building/building.request";

const repository = BuildingRepository

export const useBuildingStore = defineStore({
    id: 'Buildings',
    state: () => ({
        buildings: [] as BuildingItem[]
    }),
    getters: {
        findDepartment: (state) => {
            return (id: String) => state.buildings.find(x => x.id === id)
        }
    },
    actions: {
        getAll: async function() {
            const response = await repository.get()
            this.buildings = response.data
        },
        create: async function(buildingRequest: BuildingRequest) {
            const response = await repository.create(buildingRequest)
            this.buildings = [...this.buildings, response.data]
        },
        update: async function(id: string, buildingRequest: BuildingRequest) {
            await repository.update(id, buildingRequest)
            this.buildings = this.buildings.map(item => item.id === id ? {
                id: id,
                ... buildingRequest
            }: item )
        },
        delete: async function(id: string) {
            await repository.delete(id)
            this.buildings = this.buildings.filter(x => x.id !== id)
        }
    }
})