import Repository from '@/services/Repository';
import { AxiosResponse } from 'axios'
import { TrainingUnitItem } from '@/models/training/unit/training.unit.item';
import { TrainingUnitRequest } from '@/models/training/unit/training.unit.request';

const resource = '/training/training-units';

class TrainingUnitRepository {
    get(): Promise<AxiosResponse<TrainingUnitItem[]>> {
        return Repository.get<TrainingUnitItem[]>(resource);
    }
    create(request: TrainingUnitRequest): Promise<AxiosResponse<TrainingUnitItem>> {
        return Repository.post(resource, request);
    }
    update(id: string, request: TrainingUnitRequest): Promise<any> {
        return Repository.put(`${resource}/${id}`, request);
    }
    delete(id: string): Promise<AxiosResponse> {
        return Repository.delete(`${resource}/${id}`);
    }
}

export default new TrainingUnitRepository()
