import Repository from '@/services/Repository'
import { AxiosResponse } from 'axios'
import { ScheduleItem } from '@/models/training/schedule/schedule.item'
import { ScheduleRequest } from '@/models/training/schedule/schedule.request';

const resource = '/training/class-schedules';

class ScheduleRepository {
    get(): Promise<AxiosResponse<ScheduleItem[]>> {
        return Repository.get<ScheduleItem[]>(resource);
    }
    getByClass(classId: string): Promise<AxiosResponse<ScheduleItem[]>> {
        return Repository.get<ScheduleItem[]>(`${resource}/by-class/${classId}`);
    }
    create(request: ScheduleRequest): Promise<AxiosResponse<ScheduleItem>> {
        return Repository.post(resource, request);
    }
    update(id: string, request: ScheduleRequest): Promise<any> {
        return Repository.put(`${resource}/${id}`, request);
    }
    delete(id: string): Promise<AxiosResponse> {
        return Repository.delete(`${resource}/${id}`);
    }
}

export default new ScheduleRepository()
