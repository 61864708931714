import MenuItem from "@/models/ui/menu.item";

export function useAppMenu(): MenuItem[] {
    return [
        {
            label: 'Danh mục',
            icon: 'pi pi-list',
            items: [
                {
                    label: 'Phòng ban',
                    icon: 'pi pi-home',
                    to: '/departments'
                },
                {
                    label: 'Lý do nghỉ',
                    icon: 'pi pi-sign-out',
                    to: '/leave-reasons'
                },
                {
                    label: 'Nhân sự',
                    icon: 'pi pi-user',
                    to: '/users'
                },
                {
                    label: 'Lương',
                    icon: 'pi pi-wallet',
                    to: '/salaries'
                },
                {
                    label: 'Tòa nhà',
                    icon: 'pi pi-building',
                    to: '/buildings'
                },
                {
                    label: 'Chức danh',
                    icon: 'pi pi-credit-card',
                    to: '/titles'
                },
                {
                    label: 'Ca làm việc',
                    icon: 'pi pi-bell',
                    to: '/shifts'
                }
            ]
        },
        {
            label: 'Đào tạo',
            icon: 'pi pi-compass',
            items: [
                {
                    label: 'Hình thức đào tạo',
                    icon: 'pi pi-video',
                    to: '/training/training-types'
                },
                {
                    label: 'Chương trình đào tạo',
                    icon: 'pi pi-flag',
                    to: '/training/training-programs'
                },
                {
                    label: 'Đơn vị đào tạo',
                    icon: 'pi pi-map-marker',
                    to: '/training/training-units'
                },
                {
                    label: 'Giảng viên',
                    icon: 'pi pi-user',
                    to: '/training/teachers'
                },
                {
                    label: 'Lớp học',
                    icon: 'pi pi-tag',
                    to: '/training/classes'
                },
                {
                    label: 'Tổng số tiết đào tạo liên tục',
                    icon: 'pi pi-sliders-h',
                    to: '/training/report-number-lessons'
                },
                {
                    label: 'Các lớp đào tạo liên tục',
                    icon: 'pi pi-sliders-h',
                    to: '/training/report-by-student'
                },
                {
                    label: 'Số tiết của giảng viên theo từng lớp',
                    icon: 'pi pi-sliders-h',
                    to: '/training/report-lesson-of-teacher-by-class'
                },
                {
                    label: 'Số tiết theo giảng viên',
                    icon: 'pi pi-sliders-h',
                    to: '/training/report-lesson-of-class-by-teacher'
                }
            ]
        }
    ]
}
