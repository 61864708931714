import { ClassStatus } from "./class.status"
import { toDateValue } from '@/helper/date'

export interface ClassRequest {
    name: string
    trainingProgramId: string
    trainingTypeId: string
    trainingUnitIds: string
    fromDate: string
    toDate: string
    status: ClassStatus
}

export const emptyClassRequest: ClassRequest = {
    name: '',
    trainingProgramId: '',
    trainingTypeId: '',
    trainingUnitIds: '[]',
    fromDate: toDateValue(new Date()),
    toDate: toDateValue(new Date()),
    status: ClassStatus.Openning
}