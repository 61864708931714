import { toDateValue } from "@/helper/date"
import { ScheduleBuoi } from "./schedule.buoi"

export interface ScheduleRequest {
    classId: string
    ngay: string
    buoi: ScheduleBuoi
    noiDungBaiHoc: string
    soTietLyThuyet?: number
    soTietThucHanh?: number
    teacherId: string
    ghiChu: string
}

export const emptyScheduleRequest: ScheduleRequest = {
    classId: '',
    ngay: toDateValue(new Date()),
    buoi: ScheduleBuoi.AM,
    noiDungBaiHoc: '',
    teacherId: '',
    ghiChu: ''
}