import PasswordForm from '@/models/profile/password';
import ProfileRepository from '@/services/ProfileRepository';

export default function useProfile() {
    const verifyPassword = (passwordForm: PasswordForm) => {
        return passwordForm.oldPassword?.length >= 3 && passwordForm.newPassword?.length >= 3;
    }

    const  changePassword = async (passwordForm: PasswordForm): Promise<any> => {
        return ProfileRepository.changePassword(passwordForm);
    }

    return {
        verifyPassword,
        changePassword
    }
}
