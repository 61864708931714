import { defineStore } from "pinia";
import StudentRepository from "@/services/training/StudentRepository"
import { StudentItem } from "@/models/training/student/student.item"
import { StudentRequest } from "@/models/training/student/student.request";
import { StudentResultRequest } from '@/models/training/student/student.result.request';

export const useStudentStore = defineStore({
    id: 'Student state',
    state: () => ({
        students: [] as StudentItem[]
    }),
    getters: {
        findStudent: (state) => {
            return (id: String) => state.students.find(x => x.id === id)
        }
    },
    actions: {
        getAll: async function () {
            const response = await StudentRepository.get()
            this.students = response.data
        },
        getByClass: async function (classId: string) {
            const response = await StudentRepository.getByClass(classId)
            this.students = response.data
        },
        createMulti: async function (requests: StudentRequest[]) {
            const response = await StudentRepository.createMulti(requests)
            this.students = [...this.students, ...response.data]
        },
        updateResult: async function (requests: StudentResultRequest[]) {
            const response = await StudentRepository.updateResult(requests);
            let unRequestStudents = this.students.filter(x => !requests.find(y => y.id === x.id));
            this.students = [...unRequestStudents, ...response.data]
        },
        delete: async function (id: string) {
            await StudentRepository.delete(id)
            this.students = this.students.filter(x => x.id !== id);
        }
    }
})
