import {defineStore} from "pinia";
import TitleRepository from "@/services/TitleRepository";
import {TitleItem} from "@/models/title/title.item";
import {TitleRequest} from "@/models/title/title.request";

const repository = TitleRepository

export const useTitleStore = defineStore({
    id: 'Titles',
    state: () => ({
        titles: [] as TitleItem[]
    }),
    getters: {
        findDepartment: (state) => {
            return (id: String) => state.titles.find(x => x.id === id)
        }
    },
    actions: {
        getAll: async function() {
            const response = await repository.get()
            this.titles = response.data
        },
        create: async function(titleRequest: TitleRequest) {
            const response = await repository.create(titleRequest)
            this.titles = [...this.titles, response.data]
        },
        update: async function(id: string, titleRequest: TitleRequest) {
            await repository.update(id, titleRequest)
            this.titles = this.titles.map(item => item.id === id ? {
                id: id,
                ... titleRequest
            }: item )
        },
        delete: async function(id: string) {
            await repository.delete(id)
            this.titles = this.titles.filter(x => x.id !== id)
        }
    }
})