import axios from 'axios'
import router from '../router'
import moment from 'moment'
import RepositoryErrorHandler from '@/services/RepositoryErrorHandler'
import { useAuthStore } from "@/store/auth"

const baseDomain = ''
const baseAPI = `${baseDomain}/api`


Date.prototype.toISOString = function() {
  return moment(this).format('YYYY-MM-DDTHH:mm:ss');
};

const instance = axios.create({
  baseURL: baseAPI,
});

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === "string" && isoDateFormat.test(value);
}

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];

    if (isIsoDateString(value)) {
      body[key] = moment(value).toDate();
    }
    else if (typeof value === "object") handleDates(value);
  }
}

instance.interceptors.request.use((request) => {
  if(request !== null && request.url){
    const authStore = useAuthStore()
    const isLogin = request.url.startsWith('/auth') && request.method === 'post';

    if (authStore.token && !isLogin) {
      request.headers['Authorization'] = `Bearer ${authStore.token}`;
    } else {
      delete request.headers['Authorization'];
    }
  }

  return request;
});

instance.interceptors.response.use(
  (response) => {
    handleDates(response.data);
    return response;
  },
  (error) => {
    if (error.response.status == 401) {
      router.push('login');
    }else{
      if(error.response){
        if(error.response.data){
          RepositoryErrorHandler.handle(error.response.data);
        }else if(error.response.message){
          RepositoryErrorHandler.handle(error.response.message);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
