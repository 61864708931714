import { defineStore } from "pinia";
import { NumberLessonResponse } from '@/models/training/report/number.lesson.response'
import ReportRepository from '@/services/training/ReportRepository';
import { ByStudentResponse } from '@/models/training/report/by.student.response';
import { LessonOfTeacherByClassResponse } from '@/models/training/report/lesson.of.teacher.by.class.response';
import { LessonOfClassByTeacherResponse } from '@/models/training/report/lesson.of.class.by.teacher.response';

export const useReportStore = defineStore({
    id: 'Report state',
    state: () => ({
        numberLessons: [] as NumberLessonResponse[],
        byStudents: [] as ByStudentResponse[],
        lessonOfTeacherByClasses: [] as LessonOfTeacherByClassResponse[],
        lessonOfClassByTeachers: [] as LessonOfClassByTeacherResponse[]
    }),
    actions: {
        reportNumberLesson: async function (fromDate: string, toDate: string) {
            const response = await ReportRepository.reportNumberLesson(fromDate, toDate)
            this.numberLessons = response.data
        },
        reportByStudent: async function (userId: string) {
            const response = await ReportRepository.reportByStudent(userId)
            this.byStudents = response.data
        },
        reportLessonOfTeacherByClass: async function () {
            const response = await ReportRepository.reportLessonOfTeacherByClass()
            this.lessonOfTeacherByClasses = response.data
        },
        reportLessonOfClassByTeacher: async function () {
            const response = await ReportRepository.reportLessonOfClassByTeacher()
            this.lessonOfClassByTeachers = response.data
        }
    }
})
