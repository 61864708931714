import { TrainingProgramRequest } from '@/models/training/program/training.program.request'

export function validTrainingProgram(request: TrainingProgramRequest): boolean {
    return !!request.code
        && !!request.name
        && request.lesson > 0
        && !!request.degree
        && !!request.numberDecision
        && !!request.dateDecision;
}

