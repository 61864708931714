import Repository from '@/services/Repository';
import { AxiosResponse } from 'axios'
import { ClassItem } from '@/models/training/class/class.item';
import { ClassRequest } from '@/models/training/class/class.request';

const resource = '/training/classes';

class ClassRepository {
    getAll(): Promise<AxiosResponse<ClassItem[]>> {
        return Repository.get<ClassItem[]>(resource);
    }
    getById(id: string): Promise<AxiosResponse<ClassItem>> {
        return Repository.get<ClassItem>(`${resource}/${id}`);
    }
    create(request: ClassRequest): Promise<AxiosResponse<ClassItem>> {
        return Repository.post(resource, request);
    }
    update(id: string, request: ClassRequest): Promise<any> {
        return Repository.put(`${resource}/${id}`, request);
    }
    delete(id: string): Promise<AxiosResponse> {
        return Repository.delete(`${resource}/${id}`);
    }
}

export default new ClassRepository()
