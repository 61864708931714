import { toDateValue } from '@/helper/date'

export interface TrainingProgramRequest {
    code: string
    name: string
    lesson: number
    degree: string
    numberDecision: string
    dateDecision: string
}
export const emptyTrainingProgramRequest: TrainingProgramRequest = {
    code: '',
    name: '',
    lesson: 0,
    degree: '',
    numberDecision: '',
    dateDecision: toDateValue(new Date()) 
}