import { UserItem } from '@/models/user/user.item';
import Repository from './Repository';
import {AxiosResponse} from "axios";
import { UserRequest } from '@/models/user/user.request';

const resource = 'users';

class UserRepository {
    get(): Promise<AxiosResponse<UserItem[]>> {
        return Repository.get<UserItem[]>(resource);
    }

    delete(id: string): Promise<AxiosResponse> {
        return Repository.delete(`${resource}/${id}`);
    }

    update(id: string, user: UserRequest): Promise<any> {
        return Repository.put(`${resource}/${id}`, user);
    }

    create(user: UserRequest): Promise<AxiosResponse<UserItem>> {
        return Repository.post(resource, user);
    }
}

export default new UserRepository()
