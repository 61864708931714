import Repository from './Repository';
import { AxiosResponse } from 'axios'
import {BuildingItem} from "@/models/building/building.item";
import {BuildingRequest} from "@/models/building/building.request";
import {WifiItem} from "@/models/wifi/wifi.item";

const resource = '/buildings';

class BuildingRepository {
    get(): Promise<AxiosResponse<BuildingItem[]>> {
        return Repository.get<BuildingItem[]>(resource);
    }

    getWifi(id: string): Promise<AxiosResponse<WifiItem[]>> {
        return Repository.get<WifiItem[]>(`${resource}/${id}/wifi`)
    }

    delete(id: string): Promise<AxiosResponse> {
        return Repository.delete(`${resource}/${id}`);
    }

    update(id: string, building: BuildingRequest): Promise<any> {
        return Repository.put(`${resource}/${id}`, building);
    }

    create(building: BuildingRequest): Promise<AxiosResponse<BuildingItem>> {
        return Repository.post(resource, building);
    }
}

export default new BuildingRepository()