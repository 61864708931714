import {defineStore} from "pinia";
import {DepartmentRequest} from "@/models/department/department.request";
import DepartmentRepository from "@/services/DepartmentRepository";
import {DepartmentItem} from "@/models/department/department.item";

export const useDepartmentStore = defineStore({
    id: 'Departments',
    state: () => ({
        departments: [] as DepartmentItem[]
    }),
    getters: {
        findDepartment: (state) => {
            return (id: String) => state.departments.find(x => x.id === id)
        }
    },
    actions: {
        getAll: async function() {
          const response = await DepartmentRepository.get()
          this.departments = response.data
        },
        create: async function(departmentRequest: DepartmentRequest) {
            const response = await DepartmentRepository.create(departmentRequest)
            this.departments = [...this.departments, response.data]
        },
        update: async function(id: string, departmentRequest: DepartmentRequest) {
            await DepartmentRepository.update(id, departmentRequest)
            this.departments = this.departments.map(item => item.id === id ? {
                id: id,
                ... departmentRequest
            }: item )
        },
        delete: async function(id: string) {
            await DepartmentRepository.delete(id)
            this.departments = this.departments.filter(x => x.id !== id)
        }
    }
})
