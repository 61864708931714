import Repository from './Repository';
import { AxiosResponse } from 'axios'
import { DepartmentItem } from '@/models/department/department.item';
import { DepartmentRequest } from '@/models/department/department.request';

const resource = '/departments';

class DepartmentRepository{
    get(): Promise<AxiosResponse<DepartmentItem[]>> {
        return Repository.get<DepartmentItem[]>(resource);
    }

    delete(id: string): Promise<AxiosResponse> {
        return Repository.delete(`${resource}/${id}`);
    }

    update(id: string, department: DepartmentRequest): Promise<any> {
        return Repository.put(`${resource}/${id}`, department);
    }

    create(department: DepartmentRequest): Promise<AxiosResponse<DepartmentItem>> {
        return Repository.post(resource, department);
    }
}

export default new DepartmentRepository()