import Repository from './Repository';
import { AxiosResponse } from 'axios'
import {TitleItem} from "@/models/title/title.item";
import {TitleRequest} from "@/models/title/title.request";

const resource = '/manage/titles';

class TitleRepository {
    get(): Promise<AxiosResponse<TitleItem[]>> {
        return Repository.get<TitleItem[]>(resource);
    }

    delete(id: string): Promise<AxiosResponse> {
        return Repository.delete(`${resource}/${id}`);
    }

    update(id: string, titleRequest: TitleRequest): Promise<any> {
        return Repository.put(`${resource}/${id}`, titleRequest);
    }

    create(titleRequest: TitleRequest): Promise<AxiosResponse<TitleItem>> {
        return Repository.post(resource, titleRequest);
    }
}

export default new TitleRepository()